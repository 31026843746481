import { useState, useEffect } from "react";

function Resume() {
  useEffect(() => {
    document.title = "my resume ⸜(｡ ˃ ᵕ ˂ )⸝♡";
  }, []);

  return (
    <div>
      <p>here's my resume 🌊🪸🐚🐬</p>
      <br/>

      <iframe
        src="https://drive.google.com/file/d/1NOxzhv_HZYarYsZmESnCDnt2GjfY0M2S/preview"
        width="640"
        height="825"
        allow="autoplay"
      ></iframe>
    </div>
  );
}

export default Resume;
